//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "FirstScreen",
  data() {
    return {};
  },
  computed: {
    // hasImg() {
    //   return !!this.propsData.image && +this.propsData.with_image_fon;
    // },
    img() {
      if (this.propsData.image && +this.propsData.with_image_fon) {
        return {
          backgroundImage: `url('${this.path(this.propsData.image)}')`,
        };
      } else {
        return null;
      }
    },
  },
};
