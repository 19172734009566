//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
// import StyledLink from "../common/StyledLink.vue";
export default {
  components: { ButtonsTray },
  name: "ImagesThree",
  props: {
    // textSize: {
    // 	type: String,
    // 	default: "medium",
    // },
  },
  computed: {
    textSize() {
      return this.propsData.font_size;
    },
  },
};
