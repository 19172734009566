//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import ArticleBody from "../components/sections/ArticleBody.vue";
import SeeAlso from "../components/articleSections/SeeAlso.vue";
export default {
  components: { ArticleBody, SeeAlso },
  name: "ArticlePage",
  data() {
    return {
      loaded: false,
      seeAlso: null,
      constructorList: null,
      model: null,
      translate: null,
    };
  },
  created() {
    let url = "/api/blog/articles/get-by-slug";
    let props = {
      lang: this.$i18n.locale,
      slug: this.$route.params.slug,
    };
    adapter
      .post(url, props, this)
      .then((response) => {
        this.constructorList = response.constructor;
        this.model = response.model;
        this.translate = response.translate;
        // this.setMeta();
        this.seeAlso = response.see_also
        this.loaded = true;
      })
      .catch((e) => {
        this.handleError(e);
      });
  },
};
