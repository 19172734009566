//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import SectionComponent from "../components/common/SectionComponent.vue";
export default {
  name: "Landing",
  components: {
    SectionComponent,
  },
  data() {
    return {
      loaded: false,
      constructorComponents: [],
      model: null,
      translate: null,
      meta: null,
      main_screen: null,
      test: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.hash) {
        console.log(document.querySelector("#component1"));
        const ankerItem = document.querySelector(this.$route.hash);
        //   console.log(ankerItem);
        if (ankerItem) {
          ankerItem.scrollIntoView();
        }
      }
    }, 1000);
  },
  created() {
    let url;
    let props;
    switch (this.$route.name) {
      case "home":
        url = "/api/get-main-page";
        props = {
          lang: this.$i18n.locale,
        };
        break;
      case "landing":
        url = "/api/landing/get-by-slug";
        props = {
          lang: this.$i18n.locale,
          slug: this.$route.params.slug,
        };
        break;
      default:
        url = "/api/page/get-by-slug";
        props = {
          lang: this.$i18n.locale,
          slug: this.$route.params.slug,
        };
        break;
    }
    adapter
      .post(url, props, this)
      .then((response) => {
        if (response.contacts) {
          this.constructorComponents.push({
            component: "contactsComponent",
            content: {
              contacts: response.contacts,
              api_key: response.maps_api_key,
            },
            visibility: "1",
          });
          this.constructorComponents = [
            ...this.constructorComponents,
            ...response.constructor,
          ];
        } else {
          this.constructorComponents = response.constructor;
        }
        this.meta = response.meta;
        this.model = response.model;
        this.translate = response.translate;
        this.main_screen = {
          title: response.translate.title,
          description: response.translate.description,
          buttons: response.translate.main_screen,
          image: response.translate.image,
          image_mob: response.translate.image_mob,
        };
        if ("main_screen" in response.model) {
          this.main_screen = {
            ...this.main_screen,
            ...response.translate.main_screen,
          };
        }

        this.setMeta(this.meta);
        this.loaded = true;
      })
      .catch((e) => {
        this.handleError(e);
        this.route.push("404");
      });
  },
};
