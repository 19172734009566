//
//
//
//
//
//
//
//
//
//

import FormBlock from "./formBlock.vue";
import FormModal from "./FormModal.vue";
export default {
  name: "FormAdapter",
  components: { FormModal, FormBlock },
  props: {
    formId: {
      type: Number,
      default: null,
    },
    formText: {
      type: String,
      default: null,
    },
  },
  computed: {
    blockType() {
      if (this.propsData) {
        return "block";
      } else {
        return "modal";
      }
    },
  },
};
