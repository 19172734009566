import { render, staticRenderFns } from "./PartnersBlock.vue?vue&type=template&id=eaa29024&scoped=true&"
import script from "./PartnersBlock.vue?vue&type=script&lang=js&"
export * from "./PartnersBlock.vue?vue&type=script&lang=js&"
import style0 from "./PartnersBlock.vue?vue&type=style&index=0&id=eaa29024&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eaa29024",
  null
  
)

export default component.exports