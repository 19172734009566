import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import Vuelidate from 'vuelidate'
import applink from '@/components/common/AppLink.vue'
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed);
Vue.use(VueAxios, axios)
Vue.component("app-link", applink)
Vue.use(Vuelidate)
import {
    utils
} from '@/utils.js'
Vue.mixin(utils)

Vue.config.productionTip = true
Vue.config.devtools = true

router.beforeEach((to, from, next) => {
    i18n.locale = to.params.locale || 'uk';
    if (to.path.length > 1 && to.path[to.path.length - 1] == '/') {
        next({
            path: to.path.substring(0, to.path.length - 1)
        });
    } else next();
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
