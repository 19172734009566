//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccordionSection from "@/components/sections/AccordionSection.vue";
import Advantages from "@/components/sections/Advantages.vue";
import FirstScreen from "@/components/sections/FirstScreen.vue";
import ImageAndText from "@/components/sections/ImageAndText.vue";
import ImageSection from "@/components/sections/ImageSection.vue";
import ImageSlider from "@/components/sections/ImageSlider.vue";
import ImagesThree from "@/components/sections/ImagesThree.vue";
import NumbersSection from "@/components/sections/NumbersSection.vue";
import QuoteSection from "@/components/sections/quoteSection.vue";
import SimpleText from "@/components/sections/SimpleText.vue";
import Stages from "@/components/sections/Stages.vue";
import ColText from "@/components/sections/ColText.vue";
import VideoText from "@/components/sections/VideoText.vue";
import Contacts from "@/components/sections/Contacts.vue";
import Ticker from "@/components/sections/Ticker.vue";
import CardsLanding from "@/components/sections/CardsLanding.vue";
import TextNextColBtn from "@/components/sections/textNextColBtn.vue";
import QuoteSliderSection from "@/components/sections/quoteSliderSection.vue";
import TableComponent from "@/components/sections/TableComponent.vue";
// import FormWidget from "@/components/formWidget/FormWidget.vue";
import { mapGetters } from "vuex";
// import FirstScreenLanding from '@/components/sections/FirstScreen-landing.vue';
import SeeAlso from "@/components/articleSections/SeeAlso.vue";
import Cta from "@/components/sections/Cta.vue";
import FirstScreenNew from "../sections/FirstScreen-new.vue";
import LinkList from "../sections/Link-list.vue";
import TextDivider from "../sections/Text-divider.vue";
import Theses from "../sections/Theses.vue";
import FormAdapter from "../formWidget/formAdapter.vue";
import CardsSlider from "../sections/CardsSlider.vue";
import ContactsWidget from '../sections/ContactsWidget.vue';
import PartnersBlock from '../sections/PartnersBlock.vue';
import SimpleTitle from '../sections/SimpleTitle.vue';
import Professionals from '../sections/Professionals.vue';
export default {
  name: "sectionComponent",
  props: {
    main_screen: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SimpleText,
    "text-n-columns": ColText,
    ImageAndText,
    "video-and-text": VideoText,
    "images-3": ImagesThree,
    Stages,
    quotes: QuoteSection,
    "full-image": ImageSection,
    Advantages,
    accordion: AccordionSection,
    numbers: NumbersSection,
    gallery: ImageSlider,
    ticker: Ticker,
    contactsComponent: Contacts,
    FirstScreen,
    blocks: CardsLanding,
    "simple-text-btn-left": TextNextColBtn,
    "quote-slider": QuoteSliderSection,
    table_component: TableComponent,
    // form_component: FormWidget,
    // FirstScreenLanding,
    SeeAlso,
    cta: Cta,
    FirstScreenLanding: FirstScreenNew,
    LinkList,
    TextDivider,
    Theses,
    form_component: FormAdapter,
    "blocks-slider": CardsSlider,
    "contacts": ContactsWidget,
    "partners": PartnersBlock,
    SimpleTitle,
    "blocks-links": Professionals,

  },
  computed: {
    ...mapGetters(["getMenuState"]),
  },
  methods: {
    blockBackground(type, bg){
        if ( type === "transparent"){
            return `background: transparent`
        }  else if ( type === "simple" ){
            return `background: ${bg}`
        } else {
            return `background: white`
        }
    },
    sectionContainer(name) {
      if (
        name === "full-image" ||
        name === "ticker" ||
        name === "text-divider" ||
        name === "simple-text"
      ) {
        return "container-fluid";
      } else {
        return "container";
      }
    },
  },
};
