//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import DropDownLink from "../common/DropDownLink.vue";
import LangSwitch from "@/components/navigation/the-lang-switch.vue";
import LangSwitch2 from "@/components/navigation/the-lang-switch2.vue";
export default {
  components: {
    LangSwitch2,
    DropDownLink,
    LangSwitch,
  },
  name: "SiteHeader",
  data() {
    return {
      right: null,
    };
  },
  computed: {
    ...mapGetters({
      getLogo: "getLogo",
      getHeaderPhone: "getHeaderPhone",
      getHeaderMenu: "getHeaderMenu",
    }),
  },
  methods: {},
  created() {},
  mounted() {},
};
